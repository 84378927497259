
app = window.angular.module('wbd', [])

app.directive('listManager', ->
	restrict: 'A'
	link: (scope, element, attrs) ->
		updateModal = -> $('#editModal').modal(if scope.showModal then 'show' else 'hide')			
		$('#editModal').on('hidden', -> 
			scope.showModal = false
			scope.$apply()
		)
		scope.$watch('showModal', updateModal)
		
		updateImageModal = -> $('#imageModal').modal(if scope.showImageModal then 'show' else 'hide')
		$('#imageModal').on('hidden', -> 
			scope.showImageModal = false
			scope.$apply()
		)
		scope.$watch('showImageModal', updateImageModal)

		updateUsageModal = -> $('#usageModal').modal(if scope.showUsageModal then 'show' else 'hide')
		$('#usageModal').on('hidden', -> 
			console.log('hidden')
			scope.showUsageModal = false
			scope.$apply()
		)
		scope.$watch('showUsageModal', updateUsageModal)
)
app.directive('datePicker', ->
	restrict: 'A'
	require : 'ngModel'
	link: (scope, element, attrs, ngModelCtrl) -> 
		$(element).datepicker().on('changeDate', () ->
			ngModelCtrl.$setViewValue(element.triggerHandler('input').val())
			scope.$apply();
		)
)
app.directive('filesInput', ->
	restrict: 'A'
	require: 'ngModel'
	link: (scope, element, attrs, ngModelCtrl) ->
		element.on('change', (e) ->
			files = element[0].files;
			scope.files = files
		)
)

app.controller('LocalServicesCtrl',	($scope, $http) -> 

	# Initialise
	$scope.showModal = false;
	$scope.showUsageModal = false;
	
	$scope.displayTypeOptions = [
		{
			name: 'Default',
			value: 'default'
		}, 
		{
			name: 'Promotion',
			value: 'promotion'
		},
		{
			name: 'Advertising',
			value: 'advertising'
		}
	];

	# Private Methods
	refreshList = ->
		$http.get('/portal/service/list')
			.success((res) -> $scope.services = res.data)
			.error(-> console.error('Failed to get list of services'))
		$http.get('/portal/brand/list')
			.success((res) -> $scope.brands = res.data)
			.error(-> console.error('Failed to get list of brands'))
	# Public Behaviour
	$scope.addService = -> 
		$scope.form = {
			service: 
				mode: 'Add'
				name: 'New Service'
				description: ''
				imageUrl: ''
				serviceUrl: ''
				isDirect: false
				isPost: false
				submitLocation: true
				displayType: 'default'
				weight: 0
			brandIds: []
		}
		$scope.showModal = true
	$scope.editService = (id) ->
		$scope.form = $.extend(true, {}, ($scope.services.filter (s) -> s.service.id is id)[0])
		$scope.form.mode = 'Update'
		$scope.showModal = true
	$scope.viewUsages = (id) ->
		$scope.usages = null
		$http.get("/portal/service/#{id}/usages")
			.success((res) ->
				$scope.usages = res.data
				$scope.showUsageModal = true
			)
			.error(-> console.error('Failed to get usages'))
	$scope.removeService = (id) ->
		$http.get("/portal/service/#{id}/delete")
			.success(-> refreshList())
			.error(-> console.error('Failed to remove service'))
	$scope.saveService = ->
		$http.post('/portal/service', $scope.form)
			.success(-> refreshList())
			.error(-> console.error('Failure'))
		$scope.showModal = false
	$scope.dismissModal = -> $scope.showModal = false
	$scope.dismissUsageModal = -> $scope.showUsageModal = false
	$scope.toggleSelection = (id) ->
		idx = $scope.form.brandIds.indexOf(id)
		if (idx > -1)
			$scope.form.brandIds.splice(idx, 1)
		else 
			$scope.form.brandIds.push(id)
	$scope.showAreaEvent = (id) ->
	refreshList()	
)

app.controller('ExceptionCtrl',	($scope, $http) -> 

	# Initialise
	$scope.showModal = false;

	# Private Methods
	refreshList = ->
		token = Math.floor((Math.random()*100000000)+1)
		$http.get("/portal/exception/list?rand=#{token}")
			.success((res) -> $scope.exceptions = res.data)
			.error(-> console.error('Failed to get list of exceptions'))
	# Public Behaviour
	$scope.addException = -> 
		$scope.form = {
			mode: 'Add'
			exceptionDate: ''
			replacementDate: null
		}
		$scope.showModal = true
	$scope.editException = (exception) ->
		$scope.form = $.extend(true, {}, exception)
		$scope.form.mode = 'Update'
		$scope.showModal = true
	$scope.removeException = (exception) ->
		$http.post("/portal/exception/delete", exception)
			.success(-> refreshList())
			.error(-> console.error('Failed to remove exception'))
	$scope.saveException = ->
		$http.post('/portal/exception', $scope.form)
			.success(-> refreshList())
			.error(-> console.error('Failure'))
		$scope.showModal = false
	$scope.displayReplacementDate = (replacementDate) -> if replacementDate is undefined then 'None' else replacementDate
	$scope.dismissModal = -> $scope.showModal = false	

	refreshList()	
)

app.controller('WasteMaterialsCtrl', ($scope, $http) -> 

	# Initialise
	$scope.showModal = false;
	$scope.showImageModal = false;

	# Private Methods
	refreshList = ->
		token = Math.floor((Math.random()*100000000)+1)
		$http.get("/portal/wastematerial/list?rand=#{token}")
			.success((res) ->
				$scope.orderByField = 'name'
				$scope.reverseSort = false
				$scope.wasteMaterials = res.data
			)
			.error(-> console.error('Failed to get list of waste materials'))
	# Public Behaviour
	$scope.addWasteMaterial = -> 
		$scope.form = {
			mode: 'Add'
			id: null
			name: ''
			notes: ''
			binType: null			
			imageUrl: 'https://whatbinday.com/api/images/garbage-bag.jpg'
		}
		$scope.showModal = true
	$scope.editWasteMaterial = (material) ->
		$scope.form = $.extend(true, {}, material)
		$scope.form.mode = 'Update'
		$scope.showModal = true
	$scope.updateImage = (material) ->
		$scope.imageForm =
			id: material.id
			imageUrl: material.imageUrl
			uploadError: ''
		$scope.showImageModal = true
	$scope.removeWasteMaterial = (material) ->
		$http.post("/portal/wastematerial/delete", material)
			.success(-> refreshList())
			.error(-> console.error('Failed to remove Waste Material'))
	$scope.saveWasteMaterial = ->
		$http.post('/portal/wastematerial', $scope.form)
			.success(-> refreshList())
			.error(-> console.error('Failure'))
		$scope.showModal = false
	$scope.uploadWasteMaterialImage = ->
		$scope.setUploadError('')
		$scope.imageForm.submitted = true
		d = new FormData()
		d.append('file', $scope.files[0])
		$.ajax({
			type: "POST"
			url: '/portal/wastematerial/' + $scope.imageForm.id + '/image'
			data: d
			processData: false
			contentType: false
			success: (res) ->
				$scope.showImageModal = false
				refreshList()
			error: (res) ->
				$scope.setUploadError(res.responseText)
				$scope.imageForm.submitted = false
			traditional: true
			async: false
		})
	$scope.displayReplacementDate = (replacementDate) -> if replacementDate is undefined then 'None' else replacementDate
	$scope.dismissModal = -> $scope.showModal = false
	$scope.dismissImageModal = -> $scope.showImageModal = false	
	$scope.setUploadError = (err) -> $scope.imageForm.uploadError = err
	refreshList()	
)

app.controller('AreaServicesCtrl', ($scope, $http) ->
	# Private Methods
	loadList = ->
		$http.get('/portal/service/approvedList')
			.success((res) -> $scope.services = res.data)
			.error(-> console.error('Failed to get list of services'))
	syncAreaServices = ->
		$http.post("/portal/service/area/#{$scope.areaId}", $scope.areaServices)
			.success((res) -> $scope.areaServices = res.data)
			.error(-> console.error('Failed to sync area'))
	findService = (serviceId) -> ($scope.services.filter (s) -> s.id is serviceId)[0]
	serviceIsActive = (serviceId) ->
		return false if not $scope.areaServices?
		(i for i,s of $scope.areaServices when s.id is serviceId)[0] > -1
	# Public Methods
	$scope.serviceToggleClass = (serviceId) -> if serviceIsActive(serviceId) then "btn-success" else "btn-light"
	$scope.serviceToggleText = (serviceId) -> if serviceIsActive(serviceId) then "Linked" else "Inactive"
	$scope.toggleSelection = (serviceId) ->
		idx = (i for i,s of $scope.areaServices when s.id is serviceId)[0]
		if (idx > -1)
			$scope.areaServices.splice(idx, 1)
		else
			$scope.areaServices.push(findService(serviceId))
		syncAreaServices()
	$scope.serviceAvailable = (serviceId) -> serviceIsActive(serviceId)		

	loadList()
	syncAreaServices()
)